@import url('https://fonts.googleapis.com/css?family=Nunito');

:root {
    --checkbox-size: 40px;
    --checkbox-size1: 15px;
    --checkbox-size2: 25px;
    --checkbox-size3: 42px;
    --checkbox-size4: 35px;
    
    --bg-color: white;
    --checkbox-color: gray;
    --hover-color: black;
    --tick-color: #ADCDBA;
    --x-color: #942525e8;
}
/* :root {
    --checkbox-size: 12;
    --checkbox-sizebig: 27;

    
    --bg-color: white;
    --checkbox-color: gray;
    --hover-color: #338353;
    --tick-color: #ADCDBA;
} */


.change-label2a {
    display: inline-block;
    margin: 7px;
    width: 27px;
    height: 27px;
    border: 1px solid var(--checkbox-color);
    border-radius: 4px;
    -o-transition: 400ms 100ms ease-out;
    transition: 400ms 100ms ease-out;
}

.change-label2a:hover {
    border-color: var(--hover-color);
}



input[type="checkbox"] {
    position: absolute;
    left: -1000px;
}

.tick {
    z-index:0;
    position: relative;
    right: calc(var(--checkbox-sizebig) * -0.5);
    top: calc(var(--checkbox-sizebig) * -0.75);
    width: calc(var(--checkbox-sizebig) * 0.55);
    height: calc(var(--checkbox-sizebig) * 0.45);
    border-right: calc(var(--checkbox-sizebig) * 0.2) solid var(--tick-color);
    border-bottom: calc(var(--checkbox-sizebig) * 0.2) solid var(--tick-color);
    -webkit-transform: rotate(45deg) scale(0);
    -ms-transform: rotate(45deg) scale(0);
    transform: rotate(40deg) scale(0);
    opacity: 0;
    -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    -o-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}

    .tick:before {
        content: '';
        position: absolute;
        left: calc(var(--checkbox-sizebig) * -0.125);
        bottom: calc(var(--checkbox-sizebig) * -0.2);
        border: calc(var(--checkbox-sizebig) * 0.1) solid var(--tick-color);
        border-radius: 50%;
        z-index: 0;
    }

    .tick:after {
        content: '';
        position: absolute;
        right: calc(var(--checkbox-size) * -0.12);
        top: calc(var(--checkbox-size) * -0.083);
        border: calc(var(--checkbox-size) * 0.06) solid var(--tick-color);
        border-radius: 50%;
        z-index: 0;
    }


    input[type="checkbox"]:checked + label .tick {
        opacity: 1;
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        z-index: 0;
    }







    .change-label2b {
        display: inline-block;
        margin: 7px;
        width: 15px;
        height: 15px;
        border: 1px solid var(--checkbox-color);
        border-radius: 4px;
        -o-transition: 400ms 100ms ease-out;
        transition: 400ms 100ms ease-out;
    }
    
        .change-label2b:hover {
            border-color: var(--hover-color);
        }
    input[type="checkbox"]:checked + label .tickFix {
        opacity: 1;
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        z-index: 0;
}

/* 
to change where the checks are located with the before and after ticks change right and top for x and y access.
to control the width of the midding of the checkbox change the borders.
everything else does not need to be touched.
*/
.tickFix {
    z-index:0;
    position: relative;
    right: calc(var(--checkbox-size) * -0.2);
    top: calc(var(--checkbox-size) * -0.1);
    width: calc(var(--checkbox-size) * 0.15);
    height: calc(var(--checkbox-size) * 0.35);
    border-right: calc(var(--checkbox-size) * 0.12) solid var(--tick-color);
    border-bottom: calc(var(--checkbox-size) * 0.12) solid var(--tick-color);
    -webkit-transform: rotate(45deg) scale(0);
    -ms-transform: rotate(45deg) scale(0);
    transform: rotate(40deg) scale(0);
    opacity: 0;
    -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    -o-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}
/* 
        This is a circle of green located at the left end of the checkmark.
        the right controls where the circles is on the x axis.
        the rop controls the y
        the border controls how big the checks are. 
    */
    .tickFix:before {
        content: '';
        position: absolute;
        left: calc(var(--checkbox-size) * -0.08);
        bottom: calc(var(--checkbox-size) * -0.12);
        border: calc(var(--checkbox-size) * 0.06) solid var(--tick-color);
        border-radius: 71%;
        z-index: 0;
    }
 
    /* 
        This is a circle of green located at the top right of the checkmark.
        the right controls where the circles is on the x axis.
        the top controls the y
        the border controls how big the checks are. 
    */
    .tickFix:after {
        content: '';
        position: absolute;
        right: calc(var(--checkbox-size) * -0.12);
        top: calc(var(--checkbox-size) * -0.083);
        border: calc(var(--checkbox-size) * 0.06) solid var(--tick-color);
        border-radius: 60%;
        z-index: 0;
    }






/* change label 2 */
    .change-label2 {
        display: inline-block;
        margin: 7px;
        margin-right: 10px;
        margin-left: 10px;
        width: 25px;
        height: 25px;
        border: 1px solid var(--checkbox-color);
        border-radius: 4px;
        -o-transition: 400ms 100ms ease-out;
        transition: 400ms 100ms ease-out;
    }
    
        .change-label2:hover {
            border-color: var(--hover-color);
        }
    input[type="checkbox"]:checked + label .tickFix1 {
        opacity: 1;
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        z-index: 0;
}

input[type="checkbox"]:checked + label .xFix {
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    z-index: 0;
}

/* 
to change where the checks are located with the before and after ticks change right and top for x and y access.
to control the width of the midding of the checkbox change the borders.
everything else does not need to be touched.
*/
.tickFix1 {
    z-index:0;
    position: relative;
    right: calc(var(--checkbox-size2) * -0.3);
    top: calc(var(--checkbox-size2) * -0.18);
    width: calc(var(--checkbox-size2) * 0.5);
    height: calc(var(--checkbox-size2) * 0.8);
    border-right: calc(var(--checkbox-size2) * 0.2) solid var(--tick-color);
    border-bottom: calc(var(--checkbox-size2) * 0.2) solid var(--tick-color);
    -webkit-transform: rotate(45deg) scale(0);
    -ms-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    opacity: 0;
    -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    -o-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}
/* 
        This is a circle of green located at the left end of the checkmark.
        the right controls where the circles is on the x axis.
        the rop controls the y
        the border controls how big the checks are. 
    */
    .tickFix1:before {
        content: '';
        position: absolute;
        left: calc(var(--checkbox-size2) * -0.08);
        bottom: calc(var(--checkbox-size2) * -0.2);
        border: calc(var(--checkbox-size2) * 0.1) solid var(--tick-color);
        border-radius: 50%;
        z-index: 0;
    }
 
    /* 
        This is a circle of green located at the top right of the checkmark.
        the right controls where the circles is on the x axis.
        the rop controls the y
        the border controls how big the checks are. 
    */
    .tickFix1:after {
        content: '';
        position: absolute;
        right: calc(var(--checkbox-size2) * -0.2);
        top: calc(var(--checkbox-size2) * -0.1);
        border: calc(var(--checkbox-size2) * 0.1) solid var(--tick-color);
        border-radius: 50%;
        z-index: 0;
    }


    .xFix {
        position: relative;
        width: var(--checkbox-size2);
        height: var(--checkbox-size2);
        opacity: 0; rotate: 155deg;
        transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    }
    
    .xFix:before, .xFix:after {
        content: '';
        position: absolute;
        top: 108%;
        left: 20%;
        width: calc(var(--checkbox-size2) * 1.28); /* Adjust the width of the lines */
        height: calc(var(--checkbox-size2) * 0.1); /* Adjust the height/thickness of the lines */
        background: var(--x-color);
        rotate: 55deg;
        border-radius: calc(var(--checkbox-size2) * 0.05); /* Optional for rounded corners */
    }
    
    .xFix:before {
        transform: translate(-50%, -50%) rotate(45deg); /* Rotate 45 degrees */
    }
    
    .xFix:after {
        transform: translate(-50%, -50%) rotate(-45deg); /* Rotate -45 degrees */
    }
    

