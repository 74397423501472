@import url(https://fonts.google.com/specimen/Inria+Serif?query=Inria);
@import url(https://fonts.google.com/specimen/Roboto+Slab);
@import url(https://fonts.google.com/specimen/DM+Serif+Display);
@import url(https://fonts.google.com/noto/specimen/Noto+Sans+Georgian);

.App {
  text-align: center;
}

hr {
  border: none;
    height: calc(.25rem + 0.15vw);
    width:100%;
    
    margin-top: .92rem;
    margin-bottom: .92rem;
    background-image: url('./pics/hr.webp');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    opacity: .88;
}


.handle {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease; 
}

pin:hover .handle {
  visibility: visible;
  opacity: 1;
}

.hover {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease; 
}

hovering:hover .hover {
  visibility: visible;
  opacity: 1;
}

/* Style for the container */
.hover-container {
  position: relative;
  overflow: visible;
  display: inline-block; /* Keeps the container size to the content */
}

/* Initially hide the div */
.hover-div {
  display: none !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition:all 0.5 ease-in-out; 
  /* Add other styling as needed */
}

/* Show the div on hover */
.hover-container:hover .hover-div {
  display: block !important;
}

/* Style for the container */
.hover-containerInt {
  position: relative;
  display: inline-block; /* Keeps the container size to the content */
}

/* Initially hide the div */
.hover-divInt {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transition:all 0.3s ease; 
  /* Add other styling as needed */
}

/* Show the div on hover */
.hover-containerInt:hover .hover-divInt {
  display: block;
}

.saved-animation {
  position: absolute;
  bottom: -20px;
  left: 0;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  
  animation: slideAndFade 1.72s ease-out forwards;
}



@keyframes slideAndFade {
  0% {
    left: 0;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    left: 86px;
    opacity: .89;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}

.indent-on-click:active {
  box-shadow: inset 3px 3px 3px rgba(243, 242, 242, 0.65),  2px 2px 2px rgba(0, 0, 0, 0.95);
  transform: scale(0.97);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 9px;
  grid-auto-rows: 10px; /* initial height; will be expanded by content */
  transition: all 0.3s ease-in-out;
}

.image-grid img {
  width: 100%;
  height: auto;
  transition: all 0.3s ease-in-out;
}

.hover-img:hover {
  opacity: .99;
  transform: scale(1.02);
  filter: contrast(1);
  border-radius: 11px;
  
  transition: all 0.3s ease-out;
}
.hover-img:active {
  opacity: .92;
  transform: scale(1.02);
  filter: blur(1px);
  filter: contrast(.89);
  border-radius: 11px;
  transition: all 0.3s ease-out;
}
.hover-img {
  opacity: 1;
  transition: all 0.3s ease-out;
  filter: contrast(.89);
}

.hover-btn:hover {
  opacity: .99;
 
  filter: contrast(1.2);
  border-radius: 11px;
  
  transition: all 0.5s ease-in-out;
}
.hover-btn:active {
  opacity: .82;
  
  filter: contrast(1);
  border-radius: 11px;
  transition: all 0.5s ease-in-out;
}
.hover-btn {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  /* filter: contrast(.89); */
}

.hover-btn-highlight:hover {
  opacity: 1;
  scale: 1.04;
  
  background-color: rgba(245, 245, 245, 0.068);
  filter: contrast(1.2) hue-rotate(-49deg);
  border-radius: 11px;
  
  transition: all 0.5s ease-in-out;
}
.hover-btn-highlight:active {
  opacity: .82;
  
  filter: contrast(1);
  border-radius: 11px;
  transition: all 0.5s ease-in-out;
}
.hover-btn-highlight {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  /* filter: contrast(.89); */
}

        .hover-stacked:hover {
          opacity: .99;
        
          filter: contrast(1.06);
          border-radius: 11px;
          
          transition: all 0.5s ease-in-out;
        }
        .hover-stacked:active {
          opacity: .82;
          transform: scale(.99);
          filter: contrast(1);
          border-radius: 11px;
          transition: all 0.5s ease-in-out;
        }
        .hover-stacked {
          opacity: 1;
          transition: all 0.5s ease-in-out;
          /* filter: contrast(.89); */
        }

        

.hover-bubble:hover {
  opacity: .99;
  transform: scale(1.001);
  filter: contrast(1);
  border-radius: 11px;
  
  transition: all 0.3s ease-out;
}
.hover-bubble:active {
  opacity: .92;
  
  filter: contrast(.89);
  border-radius: 11px;
  transition: all 0.3s ease-out;
}
.hover-bubble {
  opacity: 1;
 
  transition: all 0.3s ease-out;
  filter: contrast(.89);
}

.soft-out {
  transition: all 0.3s ease-out;
}

fontNormal {
  font-family: -apple-system, BlinkMacSystemFont, 'Inria Serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 1000px;
    width: 100%;
    position: fixed;
    letter-spacing: .21rem;
     overflow:hidden; 
}

@font-face {
  font-family: 'Inria';
  font-style: normal;
  font-weight: normal;
  src: local('Inria'), url('./fonts/InriaSerif-Regular.ttf') format('truetype');
  letter-spacing: .21rem;

  font-family: "serif";
  src:url("https://fonts.google.com/specimen/DM+Serif+Display");

  font-family: "sans-serif";
  src:url("https://fonts.google.com/noto/specimen/Noto+Sans+Georgian");
}

  @font-face {
    font-family: 'InriaBold';
    font-style: normal; 
    font-weight: normal;
    src: local('InriaBold'), url("./fonts/InriaSerif-Bold.ttf") format('truetype');
  }
  
@font-face{
  font-family: 'InriaLight';
  font-style: normal;
  font-weight: normal;
  src: local('InriaLight'), url('./fonts/InriaSerif-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Luminari';
  font-style: normal;
  font-weight: normal;
  src: local('Luminari'), url('./fonts/Luminari-Regular.woff') format('woff');
  }

.fontNormal {
  font-family: "Inria";
 }

 .fontBold {
  font-family: "InriaBold";
 }

 .fontLight {
  font-family: "InriaLight";
 }

.card {
  width: "";
  perspective: 999px;
} 
.card__inner { 
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card__inner.is-flipped {
  transform: rotateY(180deg);
}
.card__face {
  color: #00000000;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 2px;
}
.card__face--front {
  transform: none;
}

.card__face--back {
 
  transform: rotateY(180deg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  font-size: calc(1rem + 0.25vw);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'serif';
  letter-spacing: .04rem;
}

.background {
  width: 100%;
  position: absolute;
  margin-top: 700px;
  z-index: -1;
  /* opacity: .8; */
}

body {
  font-family: "Georgia" , sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subcontainer {
  position: absolute;
  margin: 0 auto;
  margin-top: 900px;
  box-shadow: 2px 2px 5px white;
  border-radius: 5px;
  background-color: white;
  display: flex;
  min-width: 45vw;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
}

.picture {
  width: 40vw;
  opacity: 1;
  margin-bottom: 30px;
}

.picturegallery {
  display: "flex";
  flex-direction: "row";
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
}

.gallerypic {
  cursor: pointer;
  width: 120px;
  margin: 10px;
  height: 120px;
  opacity: 1;
  z-index: 5;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.diapicboxa {
  position: relative;
  display: flex;
  width: 25%;
  margin: 0 auto;
  height: 350px;
  justify-content: space-between;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  flex-direction: column;
}
.popupCard {
  position: relative;
  display: flex;
  width: 25%;
  margin: 0 auto;
  height: 350px;
  justify-content: space-between;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  flex-direction: column;
}

.scroller {
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Style the scrollbar for Chrome, Safari and Opera */
.scroller::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scroller::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
}

.scroller::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5); /* Color and transparency of the thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

/* Style the scrollbar for IE, Edge, and Firefox */
.scroller {
  scrollbar-color: rgba(128, 128, 128, 0.5) transparent; /* Thumb and track color */
}

.scroller2 {
  scrollbar-width:thin;
  
  /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  overflow-y: scroll; /* Add the ability to scroll */
  
}

/* Style the scrollbar for Chrome, Safari and Opera */
.scroller2::-webkit-scrollbar {
  width: 9px; /* Width of the scrollbar */
  height: 9px;
}

.scroller2::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
  
}

.scroller2::-webkit-scrollbar-thumb {
  background-color:  rgba(128, 128, 128, 0.7); /* Color and transparency of the thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.scroller2::-webkit-scrollbar-corner {
  background-color: transparent; /* Make the corner transparent */
}

/* Style the scrollbar for IE, Edge, and Firefox */
.scroller2 {
  scrollbar-color: rgba(128, 128, 128, 0.7) transparent; /* Thumb and track color */
}

input {
  cursor: pointer;
}


.gradient-animation {
  background: linear-gradient(90deg, #282c340c, #45526e27,  #677ca811, #282c340c);
  background-size: 200% 200%;
  animation: gradient-animation 9.248s ease-in-out infinite;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  height: 80%;
  
}

.gradient-animation::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 200%;
  left: 0;
  z-index: -1;
  
  background: linear-gradient(235deg, rgba(255, 255, 255, 0.171), rgba(255, 255, 255, 0));
  width: 9%;
  filter: blur(11px);
  mix-blend-mode: screen;
  opacity: 0.4;
  animation: shimmer 12.95s ease-in infinite;
}


@keyframes gradient-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(18800%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes flash-off {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes spinAndWiggle {
  0%, 10% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-2deg);
  }
  15% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(98deg);
  }
  80% {
    transform: rotate(93deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
  
.vault-door-handle {
  animation: spinAndWiggle 3.4s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}